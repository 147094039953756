import { useState,useEffect } from "react";
import { IFeedback } from "../interfaces/IFeedback";

export function deleteFetch(endpoint:string, idToDelete:number | number[], setWasSuccessfullyDeleted:Function) {
    // Settings
    const session_token = localStorage.getItem('token');
    const requestOptions = {
        method: 'DELETE',
        headers : { "Authorization" : "Token " + session_token }
    };
    var endpoint = `/api${endpoint}`

    if (Array.isArray(idToDelete)) {
        idToDelete.map(x => endpoint = endpoint + `/${x}`)
    } else {
        endpoint = endpoint +  `${idToDelete}`
    }


    fetch(endpoint, requestOptions)
    .then(res => {
        if (res.status === 200) { setWasSuccessfullyDeleted(true);  setWasSuccessfullyDeleted(true);}
        else {throw Error(`ERROR DELETE in ${endpoint}: ${res.status}`)}
    })
    .catch((error) => {
        console.error(error);
        setWasSuccessfullyDeleted(false);
    })
}

export function uploadFetch(
    endpoint:string,
    isNew:boolean, 
    uploadlObject:any, 
    setFunction:Function, 
    setWasSuccessfullyUploaded?: Function, 
    wasSaved?:Function,
    setIsLoading?: Function
) {
    (setIsLoading) && setIsLoading(true);
    const session_token = localStorage.getItem('token');
    // Settings 
    const requestOptions = {
        method: (isNew) ? 'POST' : 'PUT',
        headers: { 'Content-Type': 'application/json', "Authorization" : "Token " + session_token },
        body: JSON.stringify(uploadlObject)
    };

    fetch(`/api${endpoint}`, requestOptions)
    .then(res => {
        if (res.status === 200) { return res.json()}
        else {throw Error(`ERROR ${(isNew) ? 'POST' : 'PUT'} in ${endpoint}: ${res.status}`)}
    })
    .then(res => {
        (setWasSuccessfullyUploaded) && setWasSuccessfullyUploaded(true);
        (wasSaved) && wasSaved(true);
        (setIsLoading) && setIsLoading(false);
        setFunction(res);
    })
    .catch((error) => {
        console.error(error);
        (setWasSuccessfullyUploaded) && setWasSuccessfullyUploaded(false);
        (wasSaved) && wasSaved(true);
        (setIsLoading) && setIsLoading(false);
    })
}

export function uploadFetchFeedback(
  endpoint:string,
  isNew:boolean, 
  uploadlObject:any, 
  setFunction:Function, 
  setIsLoading: Function,
  setFeedback: Function
) {
  (setIsLoading) && setIsLoading(true);
  const session_token = localStorage.getItem('token');
  // Settings 
  const requestOptions = {
      method: (isNew) ? 'POST' : 'PUT',
      headers: { 'Content-Type': 'application/json', "Authorization" : "Token " + session_token },
      body: JSON.stringify(uploadlObject)
  };

  let feedback:IFeedback = { message: "Gespeichert!", open: false, severity: "success" };

  fetch(`/api${endpoint}`, requestOptions)
  .then(res => {
      if (res.status === 200) { return res.json()}
      else {throw Error(`ERROR ${(isNew) ? 'POST' : 'PUT'} in ${endpoint}: ${res.status}`)}
  })
  .then(res => {
      
      //(setIsLoading) && setIsLoading(false);
      setFunction(res);
  })
  .catch((error) => {
      console.error(error);
      feedback.message = "Fehler!";
      feedback.severity = "error";
  })
  .finally(() => {
    feedback.open = true;
    setFeedback({...feedback});
    setIsLoading(false);
  })
}
export function getFetch<S>(endpoint:string,idToGet:number|string|undefined|null,setFunction?:Function,setWasSuccessfully?:Function) { 
    let targetEndpoint = `/api${endpoint}`;
    let tryFetch = true;
    const session_token = localStorage.getItem('token');
    if (endpoint.includes("/") && !endpoint.includes("?") && idToGet) {
        targetEndpoint = targetEndpoint + `${idToGet}`
        
        tryFetch = (idToGet !== undefined)
    }

    const requestOptions = {
        headers: { "Authorization" : "Token " + session_token },
    };


    if (!tryFetch) {
        return null;
    } else {
        fetch(targetEndpoint,requestOptions)
        .then(res => {
            if (res.status === 200) { return res.json()}
            else if (res.status === 204) { return null}
            else {throw Error(`ERROR GET in ${endpoint}: ${res.status}`)}
        })
        .then(res => {
            (setFunction !== undefined) && setFunction(res);
            (setWasSuccessfully !== undefined) && setWasSuccessfully(true);
        })
        .catch((error) => {
            console.error(error);
            (setWasSuccessfully !== undefined) && setWasSuccessfully(false);
        })
    }
}

export function useFetch<S>(endpoint:string | null ,targetId: number | string| null | undefined = undefined) : [S | undefined,Function,number | null] {
    const [responseValue, setResponseValue] = useState<S | undefined>(undefined);
    const [statusCode,setStatusCode] = useState<number|null>(null);
    const session_token = localStorage.getItem('token');

    const requestOptions = {
        headers: { "Authorization" : "Token " + session_token },
    };

    useEffect(() => {
        let targetEndpoint = `/api${endpoint}`;
        let execute = true;
    
        
        if (targetId !== undefined) {
            if (targetId === null || targetId === -1) {
                execute = false;
            } else {
                targetEndpoint = targetEndpoint + `${targetId}`;
            }
        }

        if (execute) {
            fetch(targetEndpoint,requestOptions)
            .then(res => {
                setStatusCode(res.status);
                if (res.status === 200) { return res.json()}
                else if (res.status === 204) { return null}
                else {throw Error(`ERROR GET in ${endpoint}: ${res.status}`)}
            })
            .then(res => {
                setResponseValue(res);
            })
            .catch((error) => {
                console.error(error,targetEndpoint);
            })
        }

    },[])


    return [
        responseValue,
        setResponseValue,
        statusCode
    ]
}

export function useSessionFetch<S>(endpoint: string | null, targetId: number | string | null | undefined = undefined): [S | undefined, Function, number | null, boolean] {
  const session_token = localStorage.getItem('token');
  const [responseValue, setResponseValue] = useState<S | undefined>(undefined);
  const [statusCode, setStatusCode] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      if (!session_token) {
        setStatusCode(403); // Forbidden if no token is found
        setLoading(false);
        return;
      }

      const requestOptions = {
        headers: {
          "Authorization": `Token ${session_token}`
        },
      };

      let targetEndpoint = `/api${endpoint}`;
      let execute = true;

      if (targetId !== undefined) {
        if (targetId === null || targetId === -1) {
          execute = false;
        } else {
          targetEndpoint = `${targetEndpoint}${targetId}`;
        }
      }

      if (execute) {
        try {
          const response = await fetch(targetEndpoint, requestOptions);
          setStatusCode(response.status);
          if (response.status === 200) {
            const result = await response.json();
            setResponseValue(result);
          } else if (response.status === 204) {
            setResponseValue(undefined);
          } else {
            throw new Error(`ERROR GET in ${endpoint}: ${response.status}`);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    // Introduce a small delay to ensure the token is set in localStorage
    const timeoutId = setTimeout(() => {
      fetchData();
    }, 500); // 500ms delay

    return () => clearTimeout(timeoutId);
  }, [endpoint, targetId, session_token]);

  return [
    responseValue,
    setResponseValue,
    statusCode,
    loading
  ];
}



export const customFetcher = async ({
  endpoint,
  method = 'GET',
  body,
  signal,
  headers,
}: {
  endpoint: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  body?: unknown;
  signal?: AbortSignal;
  headers?: Record<string, string>;
}) => {
  const sessionToken = localStorage.getItem('token');
  const apiEndpoint = `/api${endpoint}`;

  const requestOptions: RequestInit = {
    method,
    body: body ? JSON.stringify(body) : null,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${sessionToken}`,
      ...headers,
    },
    signal,
  };

  const response = await fetch(apiEndpoint, requestOptions);

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};