import React from "react";
import { IPostcode } from "../../interfaces/IPostcode";
import { ICountry } from "../../interfaces/ICountry";
import { IState } from "../../interfaces/IState";
import { Grid, MenuItem } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";


interface IProps {
    postcodeObject: IPostcode;
    setPostcodeObject: Function;
    countryArray: ICountry[];
    stateArray: IState[];
    refetchCountryState: () => void;
}


export const PostcodeEdit:React.FC<IProps> = ({
    postcodeObject,
    setPostcodeObject,
    countryArray,
    stateArray,
    refetchCountryState
}) => {
    return(
     <>
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <CustomeTextField
                    label="Land"
                    attr="country"
                    object={postcodeObject}
                    setObject={setPostcodeObject}
                    type="select"
                    required
                >
                    {countryArray.map(x => 
                        <MenuItem key={`select-country-${x.id}`} value={x.id}>
                            {x.country}
                        </MenuItem>
                    )}
                </CustomeTextField>
            </Grid>
            <Grid item xs={6}>
                <CustomeTextField
                    label="Bundesland"
                    attr="state"
                    object={postcodeObject}
                    setObject={setPostcodeObject}
                    type="select"
                    required
                >
                    {stateArray.map(x => 
                        <MenuItem key={`select-state-${x.id}`} value={x.id}>
                            {x.state}
                        </MenuItem>
                    )}
                </CustomeTextField>
            </Grid>
            <Grid item xs={4}>
                <CustomeTextField
                    label="PLZ"
                    attr="postcode"
                    object={postcodeObject}
                    setObject={setPostcodeObject}
                    type="string"
                    required
                />
            </Grid>
            <Grid item xs={8}>
                <CustomeTextField
                    label="Stadt"
                    attr="city"
                    object={postcodeObject}
                    setObject={setPostcodeObject}
                    type="string"
                    required
                />
            </Grid>
        </Grid>
     </>
    )
}