import { useMutation, useQuery } from "@tanstack/react-query";
import { customFetcher } from "../useFetch";
import { IProjectState } from "../../interfaces/IProjectState";


export const useGetProjectState = () => {
    const {
      data: projectStateArray,
      isLoading: isLoadingProjectStateArray,
      refetch: refetchProjectState,
    } = useQuery<IProjectState[]>({
      queryKey: ['project/state/'],
      queryFn: () => customFetcher({ endpoint: '/project/state/' }),
    });
    return { projectStateArray, isLoadingProjectStateArray, refetchProjectState};
  };