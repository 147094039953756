import React, { useEffect, useRef, useState } from "react";
import { IOffer } from "../../interfaces/IOffer";
import { IPartner } from "../../interfaces/IPartner";
import { IUnit } from "../../interfaces/IUnit";
import { IService } from "../../interfaces/IService";
import { IServiceCategory } from "../../interfaces/IServiceCategory";
import { IOfferState } from "../../interfaces/IOfferState";
import { IOfferType } from "../../interfaces/IOfferType";
import { IOfferPosition } from "../../interfaces/IOfferPosition";
import { Alert, Box, Button, Collapse, Grid, LinearProgress, MenuItem, Tab, Tabs, Typography } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";
import { OfferPartnerTable } from "./Childs/OfferPartnerTable";
import { OfferPositionTable } from "./Childs/OfferPositionTable";
import { IOfferPartner } from "../../interfaces/IOfferPartner";
import { uploadFetch } from "../../hooks/useFetch";
import { IDocument } from "../../interfaces/IDocument";
import { OfferApplicationOverview } from "./Childs/OfferApplicationOverview";
import { ITemplate } from "../../interfaces/ITemplate";
import { IOfferApplication } from "../../interfaces/IOfferApplication";
import { ICustomer } from "../../interfaces/ICustomer";
import { IProject } from "../../interfaces/IProject";
import { IOfferMaterialPosition } from "../../interfaces/IOfferMaterialPosition";
import { OfferMaterialPositionTable } from "./Childs/OfferMaterialPositionTable";
import { IMaterialCategory } from "../../interfaces/IMaterialCategory";
import { IMaterialSubcategory } from "../../interfaces/IMaterialSubcategory";
import { IMaterial } from "../../interfaces/IMaterial";
import { IMaterialDocument } from "../../interfaces/IMaterialDocument";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }




interface IProps {
    offerObject: IOffer;
    offerArray: IOffer[];
    setOfferArray: Function;

    projectObject: IProject;
    customerArray: ICustomer[];
    templateArray: ITemplate[];
    partnerArray: IPartner[];
    unitArray: IUnit[];
    serviceArray: IService[];
    serviceCategoryArray: IServiceCategory[];
    materialArray: IMaterial[];
    materialCategoryArray: IMaterialCategory[];
    materialSubcategoryArray: IMaterialSubcategory[];
    offerStateArray: IOfferState[];
    offerTypeArray: IOfferType[];
    materialDocumentArray: IMaterialDocument[] | undefined;
    handleSave: Function;
    handleDownload?: Function;
}


export const OfferEdit:React.FC<IProps> = ({
    offerObject,
    offerArray,
    setOfferArray,

    projectObject,
    customerArray,
    templateArray,
    partnerArray,
    unitArray,
    serviceArray,
    serviceCategoryArray,
    materialArray,
    materialCategoryArray,
    materialSubcategoryArray,
    offerStateArray,
    offerTypeArray,
    materialDocumentArray,
    handleSave,
    handleDownload,
}) => {
    const inputUpload = useRef<HTMLInputElement | null>(null);
    //
    const [offerEdit, setOfferEdit] = useState(offerObject);
    const [offerPositionArray, setOfferPositionArray] = useState<IOfferPosition[]>(offerObject.OfferPositions);
    const [offerMaterialPositionArray, setOfferMaterialPositionArray] = useState<IOfferMaterialPosition[]>(offerObject.OfferMaterialPositionArray);
    const [offerPartner,setOfferPartner] = useState<IOfferPartner[]>(offerObject.OfferPartners);
    const [offerApplicationArray,setOfferApplicationArray] = useState<IOfferApplication[]>(offerObject.OfferApplications);
    //const [partnerIdArray,setPartnerIdArray] = useState<number[]>([]);
    const [wasSuccessfullySaved,setWasSuccessfullySaved] = useState(true);
    const [isLoading,setIsLoading] = useState(false);
    const [isOpenAdd, setIsOpenAdd] = useState(false);
    const [isOpenAddMaterial, setIsOpenAddMaterial] = useState(false);
    const [isOpenAssignMater,setIsOpenAssignMater] = useState(false);
    //

    const [value, setValue] = React.useState(0);

    useEffect(() => {
        if (offerPartner.length === 0) {
            setOfferPartner([
                {
                    id: -1,
                    Offer: offerEdit.id,
                    Partner: null,
                    selling_price_offer: 80
                } as IOfferPartner
            ])
        }
    },[offerPartner])


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
      console.log(projectObject)
    };
  

    
    const updateAfterSave = (updatedOffer:IOffer) => {
        let foundOffer = offerArray.find(x => x.id === updatedOffer.id);
        if (foundOffer === undefined) {
            setOfferArray([
                ...offerArray,
                updatedOffer
            ])
            // Dass die PDF direkt generiert wird.
        }
        else {
            setOfferArray([
                ...offerArray.map(x => x.id === updatedOffer.id ? updatedOffer : x )
            ])
        }
        handleSave();
    }

    const createAfterSave = (res:IOffer) => {
        let updatesObject:IOffer = {
            ...res,
            OfferPositions: offerPositionArray.map(x => { return {...x, Offer: res.id} }),
            OfferPartners: offerPartner.map(x => { return {...x, Offer: res.id} }),
            OfferMaterialPositionArray: offerMaterialPositionArray.map(x => { return {...x, Offer: res.id} }),
        }
        console.log(updatesObject)
        uploadFetch(`/offer/${updatesObject.id}`,false,updatesObject,updateAfterSave,setWasSuccessfullySaved,()=>{},setIsLoading)
    }


    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();

        let updatesObject:IOffer = {
            ...offerEdit,
            OfferPositions: offerPositionArray.map(x => { return {...x, Offer: offerEdit.id} }),
            OfferPartners: offerPartner.map(x => { return {...x, Offer: offerEdit.id} }),
            OfferMaterialPositionArray: offerMaterialPositionArray.map(x => { return {...x, Offer: offerEdit.id} }),
        }
        //handleSave();
        //handleSave();
        console.log(offerEdit.id)
        if (offerEdit.id < 0) {
            updatesObject.OfferPositions = []
            updatesObject.OfferPartners = []
            uploadFetch("/offer/",true,updatesObject,createAfterSave,setWasSuccessfullySaved,()=>{},setIsLoading)
        }
        else {
            uploadFetch(`/offer/${offerEdit.id}`,false,updatesObject,updateAfterSave,setWasSuccessfullySaved,()=>{},setIsLoading)
        }
    }

    async function fileToBase64(file:File) {
        let result_base64 = await new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsDataURL(file);
        });
        return [String(result_base64).split(",")[1],file.name];
    }

    const handleFilesUpload = async (acceptedFiles:File[]) => {
        if (acceptedFiles.length === 1) {
            let currentFile = acceptedFiles[0];
            let updateProfile:IDocument = {id: -1, file_name: "", MIMEtype: { id: 1}};
            [updateProfile.data, updateProfile.file_name] = await fileToBase64(currentFile);

            setOfferEdit({
                ...offerEdit,
                signed_data: updateProfile.data
            } as IOffer)
        }
    }

    const handleNormalUpload = (files:null|FileList) => {
        if (files !== null) {
            let acceptedFiles:File[] = Array.from(files);
            handleFilesUpload(acceptedFiles);
        }
    }


    const handleOpenNew = ()=>{
        if (value === 0) {  
            setIsOpenAdd(true);
        } else {
            setIsOpenAssignMater(true);
        }
            
    }

    useEffect(() => {
        console.log("111");
        console.log(offerMaterialPositionArray);
    },[offerMaterialPositionArray])

    return(
        <>
                <input 
                    hidden 
                    ref={inputUpload} 
                    type="file" 
                    accept="application/pdf"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleNormalUpload(event.target.files)} 
                />

                <Box sx={{mb: 0.5}}>
                    {/*
                    { (handleDownload !== undefined && (offerObject.signed_data !== null || offerObject.data)) &&
                        <Button sx={{float: "right", mr: 2}} variant="outlined" onClick={() => handleDownload()}>Runterladen</Button>
                    }
                    { (offerObject.signed_data === null && offerObject.OfferState <= 2) &&
                        <Button sx={{float: "right", mr: 2}} variant="outlined" onClick={() => (inputUpload !== null) && inputUpload.current?.click()}>Hochladen</Button>
                    }
                    */}

                </Box>
                <br/>
                {(isLoading) &&
                    <LinearProgress sx={{m: 2}} />
                }

                <Collapse in={!wasSuccessfullySaved} sx={{mb: 5}}>
                    <Alert severity="error">
                        Das Angebot konnte nicht gespeichert werden!
                    </Alert>
                </Collapse>



                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <CustomeTextField
                            label="Titel"
                            attr="title"
                            type="string"
                            object={offerEdit}
                            setObject={setOfferEdit}
                            required
                            disabled={offerEdit.OfferState > 2}
                        />
                    </Grid>

                    <Grid item sm={6}>
                        <CustomeTextField
                            label="Angebotsart"
                            attr="OfferType"
                            type="select"
                            object={offerEdit}
                            setObject={setOfferEdit}
                            required
                            disabled={offerEdit.OfferState > 2}
                        >
                            {offerTypeArray.map(x =>
                                <MenuItem key={`offertype-${x.id}`} value={x.id}>{x.offerType}</MenuItem>
                            )}
                        </CustomeTextField>
                    </Grid>

                    <Grid item sm={6}>
                        <CustomeTextField
                            label="Angebotstatus"
                            attr="OfferState"
                            type="select"
                            object={offerEdit}
                            setObject={setOfferEdit}
                            required
                        >
                            {offerStateArray.map(x =>
                                <MenuItem disabled={x.id < offerObject.OfferState} key={`offerstate-${x.id}`} value={x.id}>{x.offerState}</MenuItem>
                            )}
                        </CustomeTextField>
                    </Grid>
                </Grid>

                <Collapse sx={{mt: 5}} in={offerEdit.OfferState > 2}>
                    <Alert severity="warning">
                        Änderungen sind ab dem Status <i>Angenommen</i> nicht mehr möglich!
                    </Alert>
                </Collapse>

                <Collapse sx={{mt: 5}} in={offerPartner.length === 0}>
                    <Alert severity="info">
                        Bitte füngen Sie mindestens einen Partner hinzu, um fortfahren zu können
                    </Alert>
                </Collapse>


                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Leistungsangebot" {...a11yProps(0)} />
                            <Tab label="Materialangebot" {...a11yProps(0)} />
                            {/*<Tab label="Anträge" {...a11yProps(1)}/>*/}
                        </Tabs>
                    </Box>


                    <CustomTabPanel value={value} index={0}>
                        <>
                            <Box sx={{mt: 5}}>
                                <OfferPartnerTable
                                    offerObject={offerObject}
                                    partnerArray={partnerArray}
                                    offerPartner={offerPartner}
                                    setOfferPartner={setOfferPartner}
                                />
                            </Box>


                            <Box sx={{mt: 10}}>
                                <OfferPositionTable
                                    offerObject={offerObject}
                                    offerPositionArray={offerPositionArray}
                                    setOfferPosition={setOfferPositionArray}
                                    offerPartnerArray={offerPartner}
                                    partnerArray={partnerArray}
                                    serviceArray={serviceArray}
                                    serviceCategoryArray={serviceCategoryArray}
                                    isOpenAdd={isOpenAdd}
                                    setIsOpenAdd={setIsOpenAdd}
                                    unitArray={unitArray}
                                />
                            </Box>
                        </>
                    </CustomTabPanel>



                    <CustomTabPanel value={value} index={1}>
                        <OfferMaterialPositionTable
                            offerObject={offerObject}
                            offerMaterialPositionArray={offerMaterialPositionArray}
                            setOfferMaterialPositionArray={setOfferMaterialPositionArray}
                            unitArray={unitArray}
                            materialArray={materialArray}
                            materialCategoryArray={materialCategoryArray}
                            materialSubcategoryArray={materialSubcategoryArray}
                            isOpenAddMaterial={isOpenAddMaterial}
                            setIsOpenAddMaterial={setIsOpenAddMaterial}
                            isOpenAssignMaterial={isOpenAssignMater}
                            setIsOpenAssignMaterial={setIsOpenAssignMater}
                            materialDocumentArray={materialDocumentArray}
                        />
                    </CustomTabPanel>


                    <Box sx={{width: 140, height: 60, float: "right"}} style={{
                            position: 'sticky',
                            bottom: 30,
                            right: 0,
                            zIndex: 100,
                            padding: 10,
                            background: "white",
                            border: "10px solid white"
                            }} >
                        <Button style={{
                            position: 'absolute',
                            bottom: 1,
                            right: 175}} 
                            sx={{float: "right", width: 150}} 
                            disabled={offerPartner.length === 0 || offerObject.OfferState > 2} 
                            variant="contained" 
                            onClick={(handleOpenNew)} 
                        >{(value === 0) ? "Neue Position" : "Material zuweisen"}</Button>
                        {(value === 1) &&
                            <Button style={{
                                position: 'absolute',
                                bottom: 1,
                                right: 375}} 
                                sx={{float: "right", width: 150}} 
                                disabled={offerPartner.length === 0 || offerObject.OfferState > 2} 
                                variant="contained" 
                                onClick={() => setIsOpenAddMaterial(true)} 
                            >{"Leere Position"}</Button>
                        }
                    
                        <Button style={{
                            position: 'absolute',
                            bottom: 1,
                            right: 5}} sx={{float: "right"}} disabled={isLoading} variant="contained" onClick={handleSubmit}>Speichern</Button></Box>
                    
                    </Box>

                

        </>
    ) 
}