import React from "react";
import { ISystemMailAutomation } from "../../interfaces/ISystemMailAutomation";
import { IOfferState } from "../../interfaces/IOfferState";
import { IProjectState } from "../../interfaces/IProjectState";
import { ITaskState } from "../../interfaces/ITaskState";
import { IBillState } from "../../interfaces/IBillState";
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { ISystemMailSendCategory } from "../../interfaces/ISystemMailSendCategory";
import { randomInt } from "crypto";
import { getTmpId } from "../core/generic_helpders";
import { SystemMailAutomationRow } from "./SystemMailAutomationRow";


interface IProps {
    automation: ISystemMailAutomation[];
    setAutomation: Function;
    // Für Automatisierung
    systemMailSendCategoryArray: ISystemMailSendCategory[];
    offerStateArray: IOfferState[];
    projectStateArray: IProjectState[];
    taskStateArray: ITaskState[];
    billStateArray: IBillState[];
}

export const SystemMailAutomationTable:React.FC<IProps> = ({
    automation,
    setAutomation,
    systemMailSendCategoryArray,
    offerStateArray,
    projectStateArray,
    taskStateArray,
    billStateArray
}) => {

    const handleAddNewAutomation = () => {
        setAutomation([
            ...automation,
            {
                id: getTmpId(automation),
                BillState: null,
                OfferState: null,
                ProjectState: null,
                SystemMail: -1,
                SystemMailSendCategory: 1,
                TaskState: null,
                send_offer_state: 1
            } as ISystemMailAutomation
        ])
    }

    return(
        <>
            <Typography variant="h6">
                Automatisierungen
                <Button sx={{float: "right"}}variant="outlined" onClick={handleAddNewAutomation}>Hinzufügen</Button>
            </Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{width : "40%"}}>Bereich</TableCell>
                        <TableCell>Ausgelöst bei</TableCell>
                        <TableCell sx={{width : 90}}>Aktionen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {automation.map(auto =>
                        <SystemMailAutomationRow
                            automationObject={auto}
                            automationArray={automation}
                            setAutomationArray={setAutomation}
                            billStateArray={billStateArray}
                            offerStateArray={offerStateArray}
                            projectStateArray={projectStateArray}
                            systemMailSendCategoryArray={systemMailSendCategoryArray}
                            taskStateArray={taskStateArray}
                        />

                    )}
                </TableBody>
            </Table>
        </>
    )
}