import { useMutation, useQuery } from "@tanstack/react-query";
import { customFetcher } from "../useFetch";
import { IBillState } from "../../interfaces/IBillState";


export const useGetBillState = () => {
    const {
      data: billStateArray,
      isLoading: isLoadingBillState,
      refetch: refetchBillStateTask,
    } = useQuery<IBillState[]>({
      queryKey: ['bill/state/'],
      queryFn: () => customFetcher({ endpoint: '/bill/state/' }),
    });
    return { billStateArray, isLoadingBillState, refetchBillStateTask};
  };