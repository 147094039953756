import React, { useEffect, useContext } from 'react';
import { CircularProgress, ThemeProvider, createTheme } from '@mui/material';
import PageRouter from './components/core/PageRouter';
import { PermissionsProvider } from './contexts/permissions/PermissionProvider';
import { Login } from "./components/core/Login";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AuthRoute from './components/core/AuthRoute';
import { AuthProvider } from './contexts/auth/AuthProvider';
import { useAuthContext } from './contexts/auth/AuthContext';
import { usePermissions } from './contexts/permissions/PermissionContext';
import { OfferSignedPublic } from './components_public/OfferSignedPublic';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { de } from 'date-fns/locale';


const theme = createTheme({
  palette: {
    primary: {
      main: '#4cb1ad',
      light: '#5cd0c2',
      contrastText: '#fff',
    },
    secondary: {
      main: '#cfa128',
    },
    /*
    text: {
      primary: '#30919A',
    },
    */
  },
});

const AppContent: React.FC = () => {
  const { loading: authLoading } = useAuthContext();
  const { loading: permsLoading } = usePermissions();

  if (authLoading || permsLoading) {
    return <CircularProgress />;
  }

  return (
    <Routes>
      <Route path="/offer_public/:token" element={<OfferSignedPublic/>} />

      <Route path="/login" element={<Login />} />
      <Route path="/*" element={<AuthRoute element={<PageRouter />} />} />


    </Routes>
  );
};

const App: React.FC = () => {
  const queryClient = new QueryClient();


  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Router>
            <AuthProvider>
              <PermissionsProvider>
                <AppContent />
              </PermissionsProvider>
            </AuthProvider>
          </Router>
        </ThemeProvider>
      </QueryClientProvider>
    </LocalizationProvider>
  );
};

export default App;