import React, { useState } from "react";
import { IOffer } from "../../interfaces/IOffer";
import { IUnit } from "../../interfaces/IUnit";
import { IService } from "../../interfaces/IService";
import { IServiceCategory } from "../../interfaces/IServiceCategory";
import { IOfferState } from "../../interfaces/IOfferState";
import { IOfferType } from "../../interfaces/IOfferType";
import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, Grid, IconButton, MenuItem, Slide, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Typography } from "@mui/material";
import { IPartner } from "../../interfaces/IPartner";
import { OfferRow } from "./OfferRow";
import { OfferEdit } from "./OfferEdit";
import { IProject } from "../../interfaces/IProject";
import { TransitionProps } from "@mui/material/transitions";
import { GridCloseIcon } from "@mui/x-data-grid";
import { ICustomer } from "../../interfaces/ICustomer";
import { ITemplate } from "../../interfaces/ITemplate";
import { CustomeTextField } from "../core/CustomeTextField";
import { uploadFetch, useFetch } from "../../hooks/useFetch";
import { IMaterial } from "../../interfaces/IMaterial";
import { IMaterialCategory } from "../../interfaces/IMaterialCategory";
import { IMaterialSubcategory } from "../../interfaces/IMaterialSubcategory";
import { ISystemMail } from "../../interfaces/ISystemMail";
import { IMaterialDocument } from "../../interfaces/IMaterialDocument";



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  








export const getEmptyOffer = (projectObject: IProject) => {
    return {
        id: -1,
        margin: 0,
        OfferPositions: [],
        OfferMaterialPositionArray: [],
        OfferApplications: [],
        OfferState: 1,
        OfferType: 1,
        OfferPartners: [],
        Project: projectObject.id,
        title: "",
        vat: 0.19,
        data: null,
        signed_field: null,
        signed_data: null
    } as IOffer
}


interface IProps {
    projectObject: IProject;
    setProjectObject: Function;

    offerArray: IOffer[] | undefined;
    setOfferArray: Function;

    templateArray: ITemplate[];
    customerArray: ICustomer[];
    partnerArray: IPartner[];
    unitArray: IUnit[];
    serviceArray: IService[];
    serviceCategoryArray: IServiceCategory[];
    materialArray: IMaterial[];
    materialCategoryArray: IMaterialCategory[];
    materialSubcategoryArray: IMaterialSubcategory[];
    offerStateArray: IOfferState[];
    offerTypeArray: IOfferType[];

    materialDocumentArray: IMaterialDocument[] | undefined;

    systemMailArray: ISystemMail[];
}


export const OfferTable:React.FC<IProps> = ({
    projectObject,
    setProjectObject,

    offerArray,
    setOfferArray,

    templateArray,
    customerArray,
    partnerArray,
    unitArray,
    serviceArray,
    serviceCategoryArray,
    materialArray,
    materialCategoryArray,
    materialSubcategoryArray,
    offerStateArray,
    offerTypeArray,

    materialDocumentArray,

    systemMailArray
}) => {
    const [isOpenNew,setIsOpenNews] = useState(false);
    const [newOfferObject,setNewOfferObject] = useState(getEmptyOffer(projectObject));
    //
    const [isOpenNewEdit,setIsOpenNewEdit] = useState(false);
    console.log(offerStateArray)
    const handleOpenNewOffer = () => {
        setNewOfferObject(getEmptyOffer(projectObject));
        //setIsOpenNews(true)
        setIsOpenNewEdit(true);
    }


    const handleAfterSave = (res:IOffer) => {
        if (offerArray !== undefined) {
            setOfferArray([
                res,
                ...offerArray
            ])
            // Dass die PDF direkt generiert wird
            setIsOpenNewEdit(false);
        }

    }

    const handleSaveNew = () => {
        uploadFetch("/offer/",true,newOfferObject,handleAfterSave)
    }


    if (offerArray === undefined) {
        return(
            <>
                Bitte warten...
            </>
        )
    }
    else {
        return(
            <>
                <Dialog
                    fullScreen
                    open={isOpenNew}
                    onClose={() => setIsOpenNews(false)}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setIsOpenNews(false)}
                            aria-label="close"
                        >
                        <GridCloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Angebotseditor: Neues Angebot
                        </Typography>
                    </Toolbar>
                    </AppBar>
                    <Box style={{marginBottom: 0}} sx={{m: 5}}>
                        <OfferEdit
                            key={`offer-new`}
                            customerArray={customerArray}
                            projectObject={projectObject}
                            offerObject={newOfferObject}
                            offerArray={offerArray}
                            setOfferArray={setOfferArray}
                            offerStateArray={offerStateArray}
                            offerTypeArray={offerTypeArray}
                            templateArray={templateArray}
                            partnerArray={partnerArray}
                            serviceArray={serviceArray}
                            serviceCategoryArray={serviceCategoryArray}
                            materialArray={materialArray}
                            materialCategoryArray={materialCategoryArray}
                            materialSubcategoryArray={materialSubcategoryArray}
                            unitArray={unitArray}
                            materialDocumentArray={materialDocumentArray}
                            handleSave={() => setIsOpenNews(false)}
                        />
                    </Box>
                </Dialog>

                <Dialog open={isOpenNewEdit} onClose={() => setIsOpenNewEdit(true)}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <CustomeTextField
                                    label="Titel"
                                    attr="title"
                                    type="string"
                                    object={newOfferObject}
                                    setObject={setNewOfferObject}
                                    required
                                />
                            </Grid>

                            <Grid item sm={6}>
                                <CustomeTextField
                                    label="Angebotsart"
                                    attr="OfferType"
                                    type="select"
                                    object={newOfferObject}
                                    setObject={setNewOfferObject}
                                    required
                                >
                                    {offerTypeArray.map(x =>
                                        <MenuItem key={`offertype-${x.id}`} value={x.id}>{x.offerType}</MenuItem>
                                    )}
                                </CustomeTextField>
                            </Grid>

                            <Grid item sm={6}>
                                <CustomeTextField
                                    label="Angebotstatus"
                                    attr="OfferState"
                                    type="select"
                                    object={newOfferObject}
                                    setObject={setNewOfferObject}
                                    required
                                >
                                    {offerStateArray.map(x =>
                                        <MenuItem key={`offerstate-${x.id}`} value={x.id}>{x.offerState}</MenuItem>
                                    )}
                                </CustomeTextField>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => setIsOpenNewEdit(false)}>Abbruch</Button>
                        <Button variant="contained" disabled={newOfferObject.title === ""} onClick={handleSaveNew}>Anlegen</Button>
                    </DialogActions>
                </Dialog>
    
                <Typography variant="h5">
                    Angebote
                </Typography>
                <Button onClick={handleOpenNewOffer} sx={{float: "right"}} variant="outlined">Neues Angebot</Button>
    
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{width: 100}}>Nr.</TableCell>
                            <TableCell sx={{width: 330}}>Art</TableCell>
                            <TableCell sx={{width: 175}}>Status</TableCell>
                            <TableCell sx={{width: 200}}>Titel</TableCell>
                            <TableCell sx={{width: 170}}>Aktionen</TableCell>
                        </TableRow>
                    </TableHead>
    
                    <TableBody>
                        {offerArray.map(x =>
                            <OfferRow
                                key={`offer-${x.id}`}
                                offerObject={x}
                                offerArray={offerArray}
                                setOfferArray={setOfferArray}
                                projectObject={projectObject}
                                setProjectObject={setProjectObject}
                                customerArray={customerArray}
                                templateArray={templateArray}
                                offerStateArray={offerStateArray}
                                offerTypeArray={offerTypeArray}
                                partnerArray={partnerArray}
                                serviceArray={serviceArray}
                                serviceCategoryArray={serviceCategoryArray}
                                materialArray={materialArray}
                                materialCategoryArray={materialCategoryArray}
                                materialSubcategoryArray={materialSubcategoryArray}
                                unitArray={unitArray}
                                materialDocumentArray={materialDocumentArray}
                                systemMailArray={systemMailArray}
                            />
                        )}
                    </TableBody>
                </Table>
    
            </>
        )
    }

}