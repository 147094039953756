import React, { useEffect, useState } from "react";
import { ICustomer } from "../../interfaces/ICustomer";
import { IOffer } from "../../interfaces/IOffer";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@mui/material";
import { IOfferApplicationDocumentPublic } from "../../interfaces/IOfferApplicationDocumentPublic";
import { uploadFetch, uploadFetchFeedback } from "../../hooks/useFetch";
import { IFeedback } from "../../interfaces/IFeedback";
import { CustomSnackbar } from "../core/Snackbar";
import { ISystemMail } from "../../interfaces/ISystemMail";
import { SystemMailAutomationEditor } from "../systemMail/SystemMailAutomationEditor";
import { getEmptrySystemMail } from "../systemMail/SystemMailEdit";


interface IProps {
    offerObject: IOffer;
    selectedContact: number | undefined;
    contactArray: ICustomer[];
    systemMailArray: ISystemMail[];
}



export const OfferDocumentPublic:React.FC<IProps> = ({offerObject, selectedContact, contactArray, systemMailArray}) => {
    const [isOpenConfirm,setIsOpenConfirm] = useState(false);
    const [foundDocumentApplication, setFoundDocumentApplication] = useState<number|null>(null);
    //
    const [isOpenCheckSystemMail,setIsOpenCheckSystemMail] = useState(false);
    //
    const [isLoading,setIsLoading] = useState(false);
    const [feedback, setFeedback] = useState<IFeedback>({ open: false, message: '', severity: 'info' }); 
    //
    const [mailPayload,setMailPayload] = useState( getEmptrySystemMail());
    // 
    //const [showWasSuccessfully,setShowWasSuccessfully] = useState(false);

    useEffect(() => {
        let foundObject = offerObject.OfferApplicationDocuments?.find(x => x.Customer === selectedContact);
        console.log("Found????")
        console.log(foundObject)

        if (foundObject == undefined) {
            setFoundDocumentApplication(null);
        } else {
            setFoundDocumentApplication(foundObject.id)
        }
    },[selectedContact])

    const handleAfterSave = (res:IOfferApplicationDocumentPublic) => {
        setIsOpenConfirm(false);
        setIsOpenCheckSystemMail(false);
    }

    const handleSendPublic = () => {
        setIsOpenCheckSystemMail(false);
        if (foundDocumentApplication !== null) {
            let uploadObject:IOfferApplicationDocumentPublic = {
                id: -1,
                created_at: "",
                OfferApplicationDocument: foundDocumentApplication,
                securityToken: "",
                mail_payload: (mailPayload.is_edit == false) ? null : mailPayload
            }
            uploadFetchFeedback("/offer/applications/serve_public/",true,uploadObject,handleAfterSave,setIsLoading,setFeedback);
        }
    }

    return(
        <>
            {(isOpenCheckSystemMail) &&
                <SystemMailAutomationEditor
                    currentObject={mailPayload}
                    setCurrentObject={setMailPayload}
                    systemMailArray={systemMailArray}
                    handleSave={handleSendPublic}
                    handleAbbort={() => {setIsOpenCheckSystemMail(false); setIsOpenConfirm(false)}}
                    idTargetCategory={1}
                    idTargetState={2}
                />
            }

            <CustomSnackbar feedback={feedback} setFeedback={setFeedback}/>

            <Button variant="outlined" disabled={foundDocumentApplication === null} onClick={() => setIsOpenConfirm(true)}>online zusenden</Button>

            <Dialog open={isOpenConfirm} onClose={() => setIsOpenConfirm(false)}>
                <DialogTitle>Online-Unterzeichnung</DialogTitle>
                <DialogContent>
                    {(isLoading) && <LinearProgress/>}
                    Der Kunde erhält eine E-Mail mit einem temporären Link, um das Angebot zu unterzeichnen.
                    <br/>Sind Sie sicher, dass Sie eine entsprechende E-Mail aus dem System generieren wollen?
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} variant="outlined" onClick={() => setIsOpenConfirm(false)}>Abbruch</Button>
                    <Button disabled={isLoading} variant="contained" onClick={() => setIsOpenCheckSystemMail(true)}>Senden</Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

