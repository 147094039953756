import { useMutation, useQuery } from "@tanstack/react-query";
import { IState } from "../../interfaces/IState";
import { customFetcher } from "../useFetch";
import { ISystemMailSendCategory } from "../../interfaces/ISystemMailSendCategory";
import { ISystemMail } from "../../interfaces/ISystemMail";


export const useGetSystemMail = () => {
  const {
    data: systemMailArray,
    isLoading: isLoadingSystemMailArray,
    refetch: refetchSystemMailArray,
  } = useQuery<ISystemMail[]>({
    queryKey: ['systemmail/'],
    queryFn: () => customFetcher({ endpoint: '/systemmail/' }),
  });
  return { systemMailArray, isLoadingSystemMailArray, refetchSystemMailArray};
};


export const useGetSystemMailSendCategory = () => {
    const {
      data: systemMailSendCategoryArray,
      isLoading: isLoadingSystemMailSendCategoryArray,
      refetch: refetchSystemMailSendCategory,
    } = useQuery<ISystemMailSendCategory[]>({
      queryKey: ['systemmail/automation/category/'],
      queryFn: () => customFetcher({ endpoint: '/systemmail/automation/category/' }),
    });
    return { systemMailSendCategoryArray, isLoadingSystemMailSendCategoryArray, refetchSystemMailSendCategory};
};