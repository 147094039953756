import React, { useEffect, useState } from "react";
import { IProject } from "../../interfaces/IProject";
import { ICustomer } from "../../interfaces/ICustomer";
import { IProjectState } from "../../interfaces/IProjectState";
import { Button, Collapse, Grid, MenuItem, Tab, Tabs, Typography } from "@mui/material";
import { CustomeTextField } from "../core/CustomeTextField";
import { Box } from "@mui/system";
import { CustomerEdit } from "../customer/CustomerEdit";
import { IInsurance } from "../../interfaces/IInsurance";
import { IService } from "../../interfaces/IService";
import { IServiceCategory } from "../../interfaces/IServiceCategory";
import { IOfferType } from "../../interfaces/IOfferType";
import { IOfferState } from "../../interfaces/IOfferState";
import { IUnit } from "../../interfaces/IUnit";
import { OfferTable } from "../offer/OfferTable";
import { IOffer } from "../../interfaces/IOffer";
import { IGender } from "../../interfaces/IGender";
import { ITitle } from "../../interfaces/ITitle";
import { IPartner } from "../../interfaces/IPartner";
import { AddressEdit } from "../address/AddressEdit";
import { ParticipantsTable } from "../customer/Childs/ParticipantsTable";
import { useFetch } from "../../hooks/useFetch";
import { ITemplate } from "../../interfaces/ITemplate";
import { ApplicationTable } from "../partner/Childs/ApplicationTable";
import { ITemplateCustomer } from "../../interfaces/ITemplateCustomer";
import { IPropertyManagment } from "../../interfaces/IPropertyManagement";
import { MapSearch } from "../core/MapSearch";
import { WorkerTable } from "../customer/Childs/WorkerTable";
import { IProfile } from "../../interfaces/IUser";
import { IAddressType } from "../../interfaces/IAddress";
import { IBillState } from "../../interfaces/IBillState";
import { IBill } from "../../interfaces/IBill";
import { BillTable } from "../bill/BillTable";
import { HandoverProtocolTable } from "../handoverProtocol/HandoverProtocolTable";
import { IHandoverProtocol } from "../../interfaces/IHandoverProtocol";
import { DocumemtTable } from "../documents/DocumentTable";
import { IMIMEType } from "../../interfaces/IMIMEType";
import ValidationDialog from "../../sites/ValidationDialog";
import { IMaterial } from "../../interfaces/IMaterial";
import { IMaterialCategory } from "../../interfaces/IMaterialCategory";
import { IMaterialSubcategory } from "../../interfaces/IMaterialSubcategory";
import { ProjectNoteTable } from "./projectNote/ProjectNoteTable";
import { IProjectNote } from "../../interfaces/IProjectNote";
import { ISystemMail } from "../../interfaces/ISystemMail";
import { IMaterialDocument } from "../../interfaces/IMaterialDocument";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }



interface IProps {
    projectObject: IProject;
    setProjectObject: Function;
    projectStateArray: IProjectState[];
    setEditCustomer: Function;

    genderArray: IGender[];
    academicTitleArray: ITitle[];
    customerArray: ICustomer[];
    setCustomerArray: Function;
    userArray: IProfile[];
    insuranceArray: IInsurance[];
    setInsuranceArray: Function;
    propertyManagementArray: IPropertyManagment[];
    setPropertyManagementArray: Function;

    unitArray: IUnit[];
    addressTypeArray: IAddressType[];
    serviceArray: IService[];
    templateArray: ITemplate[];
    serviceCategoryArray: IServiceCategory[];
    materialArray: IMaterial[];
    materialCategoryArray: IMaterialCategory[];
    materialSubcategoryArray: IMaterialSubcategory[];
    offerStateArray: IOfferState[];
    offerTypeArray: IOfferType[];
    partnerArray: IPartner[];

    billStateArray: IBillState[];

    mimeTypeArray: IMIMEType[];

    systemMailArray: ISystemMail[];
    materialDocumentArray: IMaterialDocument[] | undefined;
}


export const ProjectEdit:React.FC<IProps> = ({
    projectObject, 
    setProjectObject, 
    projectStateArray,
    setEditCustomer,

    genderArray,
    academicTitleArray,
    customerArray,
    setCustomerArray,
    userArray,
    insuranceArray,
    setInsuranceArray,
    propertyManagementArray,
    setPropertyManagementArray,

    unitArray,
    addressTypeArray,
    serviceArray,
    templateArray,
    serviceCategoryArray,
    materialArray,
    materialCategoryArray,
    materialSubcategoryArray,
    offerStateArray,
    offerTypeArray,
    partnerArray,

    mimeTypeArray,
    materialDocumentArray,

    billStateArray,

    systemMailArray
}) => {
    const [mainCustomer,setMainCustumer,statusCodeCustomer] = useFetch<ICustomer>("/customer/", projectObject.MainCustomer);
    const [applicationsArray, setApplicationsArray, statusCodeApplication] = useFetch<ITemplateCustomer[]>(`/project/${projectObject.id}/applications/`);
    //
    //const [handoverProtocolArray,setHandoverProtocolArray] = useState<IHandoverProtocol[]>([]);
    const [handoverProtocolArray,setHandoverProtocolArray,statusCodeHandoverProtocol] = useFetch<IHandoverProtocol[]>("/handoverprotocol/byProject/",projectObject.id);
    //
    const [offerArray,setOfferArray,statusCodeOffer] = useFetch<IOffer[]>("/offer/byProject/",projectObject.id);
    //
    const [billArray,setBillArray,statusCodeBill] = useFetch<IBill[]>("/bill/byProject/",projectObject.id);
    const [isDocumentsWorking, setIsDocumentsWorking] = useState(false);


    //const [mainCustomer, mainCustomer] = useState(projectObject.MainCustomer);
    const [customer, setCustomer] = useState<ICustomer[]>(customerArray)

    //
    useEffect(()=>{
        setCustomerArray(customer)
    },[customer, setCustomerArray])


    useEffect(() => {
        console.log(projectObject.Participant)
    },[projectObject])

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (isDocumentsWorking) {
            return;
        }
        setValue(newValue);
    };

    useEffect(( ) => {
        if (mainCustomer !== undefined) {
            setEditCustomer(mainCustomer)
        }
    },[mainCustomer])
  

    return(
        <>

            <Grid container spacing={2} sx={{mt: 2}}>
                <Grid item sm={4}>
                    <CustomeTextField
                        label="Status"
                        attr="ProjectState"
                        type="select"
                        object={projectObject}
                        setObject={setProjectObject}
                        required
                    >
                        {projectStateArray.map(x =>
                            <MenuItem key={`projectstate-${x.id}`} value={x.id}>
                                {x.projectState}
                            </MenuItem>
                        )}
                    </CustomeTextField>
                </Grid>
                <Grid item sm={8}>
                    <CustomeTextField
                        label="Titel"
                        attr="title"
                        type="string"
                        object={projectObject}
                        setObject={setProjectObject}
                        required
                    />
                </Grid>

                <Grid item sm={12}>
                    <CustomeTextField
                        label="Projektstart"
                        attr="project_start"
                        type="date"
                        object={projectObject}
                        setObject={setProjectObject}
                        null
                    />
                </Grid>

            </Grid>

            <Grid sm={12}>
                <Collapse in={projectObject.Participant.find(x => x.is_contact_person) !== undefined || projectObject.ConstructionSite !== null}>
                    <MapSearch 
                        key={`map-participant-${projectObject.Participant.find(x => x.is_contact_person)?.id}`}
                        currentAddress={
                            (projectObject.ConstructionSite !== null) 
                            ? projectObject.ConstructionSite
                            : (customer.find(y => y.id === projectObject.Participant.find(x => x.is_contact_person)?.Customer)?.b2b) 
                                ? customer.find(y => y.id === projectObject.Participant.find(x => x.is_contact_person)?.Customer)?.Company?.Address
                                : customer.find(y => y.id === projectObject.Participant.find(x => x.is_contact_person)?.Customer)?.Address
                        }
                    />
                </Collapse>
            </Grid>
            

            <Box sx={{ width: '100%', mt: 5 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Kunden" {...a11yProps(0)} />
                        <Tab label="Adressen" {...a11yProps(1)}/>
                        {/*<Tab label="Beteiligte" {...a11yProps(2)}/>*/}
                        <Tab label="Mitarbeiter" {...a11yProps(2)}/>
                        <Tab label="Angebote" {...a11yProps(3)} />
                        <Tab label="Übergabeprotokolle" {...a11yProps(4)}/>
                        <Tab label="Rechnungen" {...a11yProps(5)}/>
                        <Tab label="Dokumente" {...a11yProps(6)} />
                        <Tab label="Notizen" {...a11yProps(7)} />
                    </Tabs>
                </Box>
                {/*
                <CustomTabPanel value={value} index={0}>
                    { (mainCustomer === undefined) ? <>Bitte warten...</>
                        :
                            <>
                                <CustomerEdit
                                    propertyManagementArray={propertyManagementArray}
                                    setPropertyManagementArray={setPropertyManagementArray}
                                    addressTypeData={addressTypeArray}
                                    customerObject={mainCustomer}
                                    setCustomerObject={setMainCustumer}
                                    insuranceArray={insuranceArray}
                                    setInsuranceArray={setInsuranceArray}
                                    genderArray={genderArray}
                                    titleArray={academicTitleArray}

                                />
                            </>
                            
                    }
                </CustomTabPanel>
                 */}

                <CustomTabPanel value={value} index={0}>
                    <ParticipantsTable
                        genderArray={genderArray}
                        titleArray={academicTitleArray}
                        insuranceArray={insuranceArray}
                        setInsuranceArray={setInsuranceArray}
                        setPropertyManagementArray={setPropertyManagementArray}
                        addressTypeArray={addressTypeArray}
                        propertyManagementArray ={propertyManagementArray}
                        projectObject={projectObject}
                        customerArray={customer}
                        setCustomerArray={setCustomer}
                        participants={projectObject.Participant}
                        setProjectObject={setProjectObject}
                        application={false}
                    />
                </CustomTabPanel>

                <CustomTabPanel value={value} index={1}>
                    <AddressEdit
                        projectObject={projectObject}
                        setProjectObject={setProjectObject}
                    />
                </CustomTabPanel>

                <CustomTabPanel value={value} index={2}>
                    <WorkerTable
                        projectObject={projectObject}
                        setProjectObject={setProjectObject}
                        workerArray={userArray}
                        worker={projectObject.Worker}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                    <OfferTable
                        projectObject={projectObject}
                        setProjectObject={setProjectObject}
                        offerArray={offerArray}
                        setOfferArray={setOfferArray}
                        customerArray={customerArray}
                        offerStateArray={offerStateArray}
                        offerTypeArray={offerTypeArray}
                        templateArray={templateArray}
                        partnerArray={partnerArray}
                        serviceArray={serviceArray}
                        serviceCategoryArray={serviceCategoryArray}
                        materialArray={materialArray}
                        materialCategoryArray={materialCategoryArray}
                        materialSubcategoryArray={materialSubcategoryArray}
                        unitArray={unitArray}
                        materialDocumentArray={materialDocumentArray}
                        systemMailArray={systemMailArray}
                    />
                </CustomTabPanel>

                <CustomTabPanel value={value} index={4}>
                    <HandoverProtocolTable
                        handoverProtocolArray={handoverProtocolArray || []}
                        setHandoverProtocolArray={setHandoverProtocolArray}
                        projectObject={projectObject}
                        setProjectObject={setProjectObject}
                        partnerArray={partnerArray}
                        unitArray={unitArray}
                        offerArray={offerArray || []}
                        offerStateArray={offerStateArray}
                        offerTypeArray={offerTypeArray}
                        customerArray={customerArray}
                        templateArray={templateArray}
                    />
                </CustomTabPanel>


                <CustomTabPanel value={value} index={5}>
                    <BillTable
                        billArray={billArray}
                        setBillArray={setBillArray}
                        projectObject={projectObject}
                        billStateArray={billStateArray}
                        customerArray={customerArray}
                        partnerArray={partnerArray}
                        serviceArray={serviceArray}
                        templateArray={templateArray}
                        serviceCategoryArray={serviceCategoryArray}
                        unitArray={unitArray}
                    />
                </CustomTabPanel>

                <CustomTabPanel value={value} index={6}>
                    <DocumemtTable
                        projectObject={projectObject}
                        setProjectObject={setProjectObject}
                        mimeTypeArray={mimeTypeArray}
                        isWorking={isDocumentsWorking}
                        setIsWorking={setIsDocumentsWorking}
                    />
                </CustomTabPanel>

                <CustomTabPanel value={value} index={7}>
                    <ProjectNoteTable
                        projectId={projectObject.id}
                        userArray={userArray}
                        noteArray={projectObject.NoteArray}
                        setNoteArray={(newArray:IProjectNote) => setProjectObject({...projectObject, NoteArray: newArray})}

                    />
                </CustomTabPanel>
            </Box>
        </>
    )

}
