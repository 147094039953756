import { IconButton, MenuItem, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ISystemMailAutomation } from "../../interfaces/ISystemMailAutomation";
import { ISystemMailSendCategory } from "../../interfaces/ISystemMailSendCategory";
import { IOfferState } from "../../interfaces/IOfferState";
import { IProjectState } from "../../interfaces/IProjectState";
import { ITaskState } from "../../interfaces/ITaskState";
import { IBillState } from "../../interfaces/IBillState";
import { CustomeTextField } from "../core/CustomeTextField";
import { Delete } from "@mui/icons-material";


interface IProps {
    automationObject: ISystemMailAutomation;
    automationArray: ISystemMailAutomation[];
    setAutomationArray: Function;
    // Für Automatisierung
    systemMailSendCategoryArray: ISystemMailSendCategory[];
    offerStateArray: IOfferState[];
    projectStateArray: IProjectState[];
    taskStateArray: ITaskState[];
    billStateArray: IBillState[];
}

export const SystemMailAutomationRow:React.FC<IProps> = ({
    automationObject,
    automationArray,
    setAutomationArray,
    systemMailSendCategoryArray,
    offerStateArray,
    projectStateArray,
    taskStateArray,
    billStateArray
}) => {
    const [editAutomationObject,setEditAutomationObject] = useState(automationObject);

    useEffect(() => {
        setAutomationArray([
            ...automationArray.map(x => x.id === editAutomationObject.id ? editAutomationObject: x)
        ])
    },[editAutomationObject])


    const handleRemoveMe = () => {
        setAutomationArray([
            ...automationArray.filter(x => x.id !== editAutomationObject.id)
        ])
    }


    return(
        <>
            <TableRow key={`automation-${editAutomationObject.id}`}>
                <TableCell>
                    <CustomeTextField
                        label="Bereich"
                        attr="SystemMailSendCategory"
                        object={editAutomationObject}
                        setObject={(updatedAuto:ISystemMailAutomation) => setEditAutomationObject({
                            ...updatedAuto,
                            send_offer_state: (updatedAuto.SystemMailSendCategory === 1) ? 1 : null,
                            TaskState: (updatedAuto.SystemMailSendCategory === 2) ? 1 : null,
                            ProjectState: (updatedAuto.SystemMailSendCategory === 3) ? 1 : null,
                            OfferState: (updatedAuto.SystemMailSendCategory === 4) ? 1 : null,
                            BillState: (updatedAuto.SystemMailSendCategory === 6) ? 10: null,
                        })}
                        type="select"
                    >
                        {systemMailSendCategoryArray.map(cat =>
                            <MenuItem key={`select-send-category-${cat.id}`} value={cat.id}>
                                {cat.systemMailSendCategory}
                            </MenuItem>
                        )}
                    </CustomeTextField>
                </TableCell>
                <TableCell>
                    {(editAutomationObject.SystemMailSendCategory === 1) 
                        ? <CustomeTextField
                            label="Status"
                            attr="send_offer_state"
                            object={editAutomationObject}
                            setObject={setEditAutomationObject}
                            type="select"
                        >
                            {[{id: 1, offerState: "Beim Zusenden"},{id: 2, offerState: "Wenn unterschrieben"}].map(x =>
                                <MenuItem key={`select-auto-values-${x.id}`} value={x.id}>
                                    {x.offerState}
                                </MenuItem>
                            )}
                        </CustomeTextField>
                        : (editAutomationObject.SystemMailSendCategory === 2) 
                        ? <CustomeTextField
                            label="Status"
                            attr="TaskState"
                            object={editAutomationObject}
                            setObject={setEditAutomationObject}
                            type="select"
                        >
                            {taskStateArray.map(x =>
                                <MenuItem key={`select-auto-values-${x.id}`} value={x.id}>
                                    {x.taskState}
                                </MenuItem>
                            )}
                        </CustomeTextField>
                        : (editAutomationObject.SystemMailSendCategory === 3) 
                        ? <CustomeTextField
                            label="Status"
                            attr="ProjectState"
                            object={editAutomationObject}
                            setObject={setEditAutomationObject}
                            type="select"
                        >
                            {projectStateArray.map(x =>
                                <MenuItem key={`select-auto-values-${x.id}`} value={x.id}>
                                    {x.projectState}
                                </MenuItem>
                            )}
                        </CustomeTextField>
                        : (editAutomationObject.SystemMailSendCategory === 4) 
                        ? <CustomeTextField
                            label="Status"
                            attr="OfferState"
                            object={editAutomationObject}
                            setObject={setEditAutomationObject}
                            type="select"
                        >
                            {offerStateArray.map(x =>
                                <MenuItem key={`select-auto-values-${x.id}`} value={x.id}>
                                    {x.offerState}
                                </MenuItem>
                            )}
                        </CustomeTextField>
                        : (editAutomationObject.SystemMailSendCategory === 6) 
                        ? <CustomeTextField
                            label="Status"
                            attr="BillState"
                            object={editAutomationObject}
                            setObject={setEditAutomationObject}
                            type="select"
                        >
                            {billStateArray.map(x =>
                                <MenuItem key={`select-auto-values-${x.id}`} value={x.id}>
                                    {x.billState}
                                </MenuItem>
                            )}
                        </CustomeTextField>
                        :<>Error</> 
                    }
                </TableCell>
                <TableCell>
                    <IconButton onClick={handleRemoveMe}><Delete/></IconButton>
                </TableCell>
            </TableRow>
        </>
    )
}