import React, { useEffect, useState } from "react";
import { deleteFetch, uploadFetch, useFetch } from "../../hooks/useFetch";
import { IService } from "../../interfaces/IService";
import {
    Alert,
    Button,
    Chip,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    LinearProgress,
    MenuItem,
    Switch,
    TextField,
    Typography,
    useScrollTrigger
} from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams, deDE } from "@mui/x-data-grid";
import { IServiceCategory } from "../../interfaces/IServiceCategory";
import { IUnit } from "../../interfaces/IUnit";
import { CustomSnackbar } from "../../components/core/Snackbar";
import { IFeedback } from "../../interfaces/IFeedback";
import { IProject } from "../../interfaces/IProject";
import { IProjectState } from "../../interfaces/IProjectState";
import { ICustomer } from "../../interfaces/ICustomer";
import { getEmptyCustomer } from "../../components/customer/CustomerEdit";
import { ProjectEdit } from "../../components/project/ProjectEdit";
import { IInsurance } from "../../interfaces/IInsurance";
import { IOfferType } from "../../interfaces/IOfferType";
import { IOfferState } from "../../interfaces/IOfferState";
import { IGender } from "../../interfaces/IGender";
import { ITitle } from "../../interfaces/ITitle";
import { IPartner } from "../../interfaces/IPartner";
import { CustomeTextField } from "../../components/core/CustomeTextField";
import { CustomerEdit } from "../../components/customer/CustomerEdit";
import { ITemplate } from "../../interfaces/ITemplate";
import { IPropertyManagment } from "../../interfaces/IPropertyManagement";
import { IAddress, IAddressType } from "../../interfaces/IAddress";
import { IProfile } from "../../interfaces/IUser";
import { Delete } from "@mui/icons-material";
import { usePermissions } from "../../contexts/permissions/PermissionContext";
import { IBillState } from "../../interfaces/IBillState";
import { IProjectParticipant } from "../../interfaces/IProjectParticipant";
import { IMIMEType } from "../../interfaces/IMIMEType";
import { IMaterial } from "../../interfaces/IMaterial";
import { IMaterialCategory } from "../../interfaces/IMaterialCategory";
import { IMaterialSubcategory } from "../../interfaces/IMaterialSubcategory";
import { useGetSystemMail } from "../../hooks/data/data_systemmail";
import { useGetMaterialDocument } from "../../hooks/data/data_material";


const handleProjectState = (id : number,projectStateArray:IProjectState[] = []) => {
    let testObject = projectStateArray.find(x => x.id === id);

    if (testObject === undefined) {
        return <Chip label="Fehler" variant="outlined" />
    } else {
        if (testObject.id === 1) { return <Chip variant="outlined" label={testObject.projectState}/> }
        else if (testObject.id === 2 || testObject.id === 3) { return <Chip label={testObject.projectState} variant="outlined" color="warning"/> }
        else if (testObject.id === 4 || testObject.id === 5) { return <Chip label={testObject.projectState} variant="outlined" color="success"/> }
        else if (testObject.id === 6 || testObject.id === 7) { return <Chip label={testObject.projectState} color="success"/> }
        else if (testObject.id === 6 || testObject.id === 7) { return <Chip label={testObject.projectState} color="success"/> }
        else if (testObject.id === 9) { return <Chip label={testObject.projectState} variant="outlined" color="error"/> }
        else if (testObject.id === 8 || testObject.id === 10) { return <Chip label={testObject.projectState} variant="outlined" color="info"/> }
        else if (testObject.id === 11) { return <Chip label={testObject.projectState} color="success"/> }
        else if (testObject.id === 11 || testObject.id === 12) { return <Chip label={testObject.projectState} color="error"/> }
        else { return <Chip label="Fehler" variant="outlined" /> }
    }
    
}


const getEmptyProject = (currentCustomer:ICustomer) => {
    return {
        id: -1,
        title: "",
        ProjectState: 1,
        ConstructionSite: null,
        DeliveryAddress: null,
        project_start: null,
        MainCustomer: null,
        Participant: [],
        Worker: [],
        Offers: [],
        Documents: [],
        NoteArray: []
    } as IProject
}


export const ProjectOverview:React.FC = () => {
    const [mimeTypeArray, setMimeTypeArray, statusCodeMimeType] = useFetch<IMIMEType[]>("/document/mimetype/");
    const [projectArray,setProjectArray,statusCodeProject] = useFetch<IProject[]>("/project/");
    const [projectStateArray,setProjectStateArray,statusCodeProjectState] = useFetch<IProjectState[]>("/project/state");
    //
    //const [postcodeArray, setPostcodeArray, statusCodePostcode ] = useFetch<IAddress[]>("/postcode/");
    const [addressArray, setAddressArray, statusCodeAddress ] = useFetch<IAddress[]>("/address/");

    const [addressTypeArray, setAddressTypeArray, statusCodeAddressType ] = useFetch<IAddressType[]>("/address/types/");
    const [genderData, setGenderData, statusCodeGender ] = useFetch<IGender[]>("/customer/gender/");
    const [titleData, setTitleData, statusCodeAcademicTitle ] = useFetch<ITitle[]>("/customer/academic-title/");
    const [customerArray,setCustomerArray,statusCodeCustomer] = useFetch<ICustomer[]>("/customer/");
    const [userArray, setUserArray, statusCodeUser] = useFetch<IProfile[]>("/user/");
    const [insuranceArray, setInsuranceCodeData, statusCodeInsurance ] = useFetch<IInsurance[]>("/insurance/")
    const [propertyManagementArray, setPropertyManagementArray, statusCodePropertyManagement] = useFetch<IPropertyManagment[]>("/property_management/");
    const [partnerArray, setPartnerArray, statusCodePartner] = useFetch<IPartner[]>("/partner");
    //
    const [billStateArray,setBillStateArray,statusCodeBillStateArray] = useFetch<IBillState[]>("/bill/state");
    //
    const [unitArray,setUnitArray,statusCodeUnit] = useFetch<IUnit[]>("/unit/");
    const [serviceArray,setServiceArray,statusCodeService] = useFetch<IService[]>("/service/");
    const [templateArray,setTemplateArray,statusCodeTemplate] = useFetch<ITemplate[]>("/template/");
    const [serviceCategoryArray,setServiceCategoryArray,statusCodeServiceCategory] = useFetch<IServiceCategory[]>("/servicecategory/");
    //
    const [materialArray, setMaterialArray, statusCodeMaterial] = useFetch<IMaterial[]>("/material/");
    const [materialCategoryArray, setMaterialCategoryArray, statusCodeMaterialCategory] = useFetch<IMaterialCategory[]>("/material/category/");
    const [materialSubcategoryArray, setMaterialSubcategoryArray, statusCodeMaterialSubcategory] = useFetch<IMaterialSubcategory[]>("/material/subcategory/");
    //
    const [offerTypeArray,setOfferTypeArray,statusCodeOfferType] = useFetch<IOfferType[]>("/offer/type");
    const [offerStateArray,setOfferStateArray,statusCodeOfferState] = useFetch<IOfferState[]>("/offer/state");
    //
    const {systemMailArray, isLoadingSystemMailArray, refetchSystemMailArray} = useGetSystemMail();
    const { materialDocumentArray, isLoadingMaterialDocumentArray, refetchMaterialDocument } = useGetMaterialDocument();
    //
    const [editProject,setEditProject] = useState(getEmptyProject(getEmptyCustomer()));
    const [wasSuccessfully,setWasSuccessfully] = useState(true);
    const [isLoadgin,setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenConfirmClose, setIsOpenConfirmClose] = useState(false);
    //
    const [feedback,setFeedback] = useState<IFeedback>({ open: false,message: "Gespeichert!", severity: "success" } as IFeedback)
    //
    const [isOpenNewProject,setIsOpenNewProject] = useState(false);
    const [newCustomer,setNewCustomer] = useState<ICustomer|null>(null);
    const [editCustomer,setEditCustomer] = useState(getEmptyCustomer());

    const [searchTerm, setSearchTerm] = useState("");
    

    //THE FOLLOWING IS ALL FOR DELETING, USING THE is_superuser attribute given by Django.
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [projectIdToDelete, setProjectIdToDelete] = React.useState<number | null>(null);
    const is_superuser = localStorage.getItem("is_superuser")
    const { permissions } = usePermissions();

    const openDeleteConfirmationDialog = (projectId: number) => {
        setProjectIdToDelete(projectId);
        setOpenDeleteDialog(true);
    };

    const closeDeleteConfirmationDialog = () => {
        setProjectIdToDelete(null);
        setOpenDeleteDialog(false);
    };

    const handleRemove = (projectId: number) => {
        if (is_superuser === "true"){
            deleteFetch("/project/", projectId,setWasSuccessfully)
            setFeedback({ open: true,message: "Projekt erfolgreich gelöscht!", severity: "success" })
            const newArray = (projectArray === undefined) ? "" : projectArray.filter(project => project.id !== projectId);
            setProjectArray(newArray)
            console.log(projectId)
            closeDeleteConfirmationDialog();
        }else{
            console.log("You have no permission.")
        }   
    };
//END OF DELETE PATTERNS


    const columns: GridColDef[] = [
        { 
            field: 'id', 
            headerName: 'Nr.', 
            width: 90 
        },
        { 
            field: 'ProjectState', 
            headerName: 'Status', 
            renderCell: (params: GridRenderCellParams<any, number>) => <>{handleProjectState(Number(params.value),projectStateArray)}</>,
            width: 150 
        },
        { 
            field: 'project_start', 
            headerName: 'Projektstart', 
            renderCell: (params: GridRenderCellParams<any, Date>) => (params.value === null) 
                ? <></> 
                : <>{new Date(String(params.value)).toLocaleDateString()}</>
            ,
            width: 150
        },
        { 

            field: 'Participant', 
            headerName: 'Kunde', 
            renderCell: (params: GridRenderCellParams<any, IProjectParticipant[]>) => {
                let testCustomer = customerArray?.find(x => x.id === params.value?.find( y => y.is_contact_person)?.Customer);

                if (testCustomer === undefined) { return <i>Konnte Kunde nicht bestimmen</i> } 
                else {
                    { return <>{testCustomer.b2b && `${testCustomer.Company?.company_name}:`} {`${testCustomer.firstname} ${testCustomer.lastname}`}</> } 
                }
            },
            width: 250
        },
        { 
            field: 'title', 
            headerName: 'Titel', 
            flex: 1
        },
        { 
            field: 'action', 
            headerName: 'Aktionen', 
            renderCell: (params: GridRenderCellParams<any, number>) => {
                return (
                    <> {(is_superuser == "true"  || permissions.includes("delete_project"))  && <>
                        <IconButton style={{position: "relative"}} size="small" onClick={() => openDeleteConfirmationDialog(params.row.id)}>
                            <Delete />
                        </IconButton>
                        <Dialog
                            open={openDeleteDialog && projectIdToDelete === params.row.id}
                            onClose={closeDeleteConfirmationDialog}
                        >
                            <DialogTitle>Bestätigung Löschung</DialogTitle>
                            <DialogContent>
                                Sind Sie sicher, dass Sie "{params.row["title"]}" löschen wollen?
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeDeleteConfirmationDialog} color="primary">
                                    Abbruch
                                </Button>
                                <Button onClick={() => handleRemove(params.row.id)} color="primary">
                                    Bestätigen
                                </Button>
                            </DialogActions>
                        </Dialog></>}
                    </>
                );
            },
            width: 100
        },
    ];


    const handleChangeId = (params: GridRowParams<any>,project:IProject|undefined=undefined) => {
        let testObject = (project === undefined) ? projectArray?.find(x => x.id === params.id) : project;

        if (testObject === undefined) {
            setEditProject(getEmptyProject(getEmptyCustomer()))
        } else {
            setEditProject(testObject);
        }
        setIsOpen(true);
    }

    const handleNew = () => {
        setEditProject(getEmptyProject(getEmptyCustomer()));
        setNewCustomer(null);
        //setIsOpen(true);
        setIsOpenNewProject(true);
    }


    const handleAfterSave = (savedObject:IService) => {
        if (projectArray !== undefined) {
            setFeedback({ open: true,message: "Gespeichert!", severity: "success" } )
        
            let testObject = projectArray.find(x => x.id === savedObject.id);

            if (testObject === undefined) {
                setProjectArray([
                    ...projectArray,
                    savedObject
                ])
            }
            else {
                setProjectArray([
                    ...projectArray.map(x => x.id === savedObject.id ? savedObject : x)
                ])
            }

            setIsOpen(false);
        }
    }

    
    const handleSave = () => {
        console.log(editProject)
        if (editProject.id < 0) {
            uploadFetch("/project/",true,editProject,handleAfterSave,setWasSuccessfully,()=>{},setIsLoading)
        } else {
            uploadFetch(`/project/${editProject.id}`,false,editProject,handleAfterSave,setWasSuccessfully,()=>{},setIsLoading)
        }
    }


    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        setWasSuccessfully(true);
        handleSave()
    }



    useEffect(() => {
        if (!wasSuccessfully) {
            setFeedback({ open: true,message: "Ein fehler ist aufgetretten!", severity: "error" } )
            setIsLoading(false);
        }
    },[wasSuccessfully])



    const handleAfterSaveNew = (newProject:IProject) => {
        if (projectArray !== undefined) {
            setProjectArray([
                ...projectArray,
                newProject
            ])
            handleChangeId({params: {id: newProject.id}} as any,newProject);
            setIsOpenNewProject(false);
            
        }

    } 

    const handleAfterCustomerSave = (newCustomer:ICustomer) => {
        if (customerArray !== undefined) {
            setCustomerArray([
                ...customerArray,
                newCustomer
            ])
    
            let uploadObject = {...editProject, MainCustomer: newCustomer.id}
    
            uploadFetch("/project/",true,uploadObject,handleAfterSaveNew,setWasSuccessfully,()=>{},setIsLoading)
        }
    } 


    const handleSubmitNew = (e: React.SyntheticEvent) => {
        e.preventDefault();

        if (newCustomer === null) {
            uploadFetch("/project/",true,editProject,handleAfterSaveNew,setWasSuccessfully,()=>{},setIsLoading)
        } else {
            let uploadObject = {
                ...newCustomer,
                Address: (newCustomer.b2b) ? undefined : newCustomer.Address,
                Company: (newCustomer.b2b) ? newCustomer.Company : undefined,
            } as ICustomer
            uploadFetch("/customer/",true,uploadObject,handleAfterCustomerSave,setWasSuccessfully,()=>{},setIsLoading)
        }
    }
    

    const handleSearchTerm = () => {

        if (projectArray === undefined) {
            return [];
        }
        else if (searchTerm === "") {
            return projectArray
        } else {
            return projectArray.filter(x =>
                String(x.title).toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                || x.Participant
                    .map(y => customerArray?.find(z => z.id === y.Customer))
                    .find(y =>
                        y?.firstname.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                        || y?.lastname.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                    )
            )
        }
    }




    if (
        (statusCodeProject !== null && statusCodeProject !== 200)
        || (statusCodeProjectState !== null && statusCodeProjectState !== 200)

        || (statusCodeAcademicTitle !== null && statusCodeAcademicTitle !== 200)
        || (statusCodeAddressType !== null && statusCodeAddressType !== 200)
        || (statusCodeGender !== null && statusCodeGender !== 200)
        || (statusCodeCustomer !== null && statusCodeCustomer !== 200)
        || (statusCodeUser !== null && statusCodeUser !== 200)
        
        || (statusCodeInsurance !== null && statusCodeInsurance !== 200)
        || (statusCodePartner !== null && statusCodePartner !== 200)
        || (statusCodePropertyManagement!== null && statusCodePropertyManagement !== 200)

        || (statusCodeUnit !== null && statusCodeUnit !== 200)
        || (statusCodeService !== null && statusCodeService !== 200)
        || (statusCodeTemplate !== null && statusCodeTemplate !== 200)
        || (statusCodeServiceCategory !== null && statusCodeServiceCategory !== 200)

        || (statusCodeMaterial !== null && statusCodeMaterial !== 200)
        || (statusCodeMaterialCategory !== null && statusCodeMaterialCategory !== 200)
        || (statusCodeMaterialSubcategory !== null && statusCodeMaterialSubcategory !== 200)

        || (statusCodeOfferState !== null && statusCodeOfferState !== 200)
        || (statusCodeOfferType !== null && statusCodeOfferType !== 200)

        || (statusCodeMimeType !== null && statusCodeMimeType !== 200)

        || (statusCodeBillStateArray !== null && statusCodeBillStateArray !== 200)
        || (isLoadingSystemMailArray === false && systemMailArray === undefined)
    ) { return <Alert severity="error">Fehler!</Alert> }
    
    else if (
        projectArray === undefined
        || projectStateArray === undefined
        
        || genderData === undefined
        || titleData === undefined
        || addressTypeArray === undefined
        || customerArray === undefined
        || userArray === undefined
        || insuranceArray === undefined
        || partnerArray === undefined
        || propertyManagementArray === undefined

        || unitArray === undefined
        || serviceArray === undefined
        || templateArray === undefined
        || serviceCategoryArray === undefined

        || materialArray === undefined
        || materialCategoryArray === undefined
        || materialSubcategoryArray === undefined

        || offerStateArray === undefined
        || offerTypeArray === undefined

        || mimeTypeArray === undefined

        || billStateArray === undefined

        || !systemMailArray
    ) { return <LinearProgress/> }

    else {
        return(
            <>
                <Dialog open={isOpenConfirmClose} onClose={() => setIsOpenConfirmClose(false)}>
                    <DialogTitle>Projekt schließen</DialogTitle>
                    <DialogContent>
                        Sind Sie sicher, dass Sie das aktuelle Projekt schließen wollen?
                        <br/>Ungespeicherte Änderungen gehen verloren.
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => setIsOpenConfirmClose(false)}>Abbruch</Button>
                        <Button variant="contained" onClick={() => {setIsOpenConfirmClose(false); setIsOpenNewProject(false); setIsOpen(false)}}>Schließen ohne zu speichern</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={isOpenNewProject} onClose={() => setIsOpenConfirmClose(true)} maxWidth="md" fullWidth>
                    <DialogTitle>Neues Projekt</DialogTitle>
                    <DialogContent >
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2} sx={{mt: 2}}>
                                <Grid item sm={12}>
                                    <CustomeTextField
                                        label="Titel"
                                        attr="title"
                                        type="string"
                                        object={editProject}
                                        setObject={setEditProject}
                                        required
                                    />
                                </Grid>

                            </Grid>
                        </form>
                    </DialogContent>
                    <DialogActions >
                        <Button  sx={{float: "right"}} onClick={() => setIsOpenNewProject(false)} variant="outlined">Abbruch</Button>
                        <Button  sx={{float: "right", ml: 2}} onClick={handleSubmitNew} variant="contained" disabled={isLoadgin}>Speichern</Button>
                    </DialogActions>
                </Dialog>


                <Dialog open={isOpen} onClose={() => setIsOpenConfirmClose(true)} maxWidth="lg" fullWidth>
                    <form  onSubmit={handleSubmit}>
                        <DialogTitle>Projekte</DialogTitle>
                        <DialogContent>
                            <ProjectEdit
                                key={`project-${editProject.id}`}
                                projectObject={editProject}
                                setProjectObject={setEditProject}
                                projectStateArray={projectStateArray}
                                setEditCustomer={setEditCustomer}

                                genderArray={genderData}
                                academicTitleArray={titleData}
                                addressTypeArray={addressTypeArray}
                                customerArray={customerArray}
                                setCustomerArray={setCustomerArray}
                                userArray={userArray}
                                insuranceArray={insuranceArray}
                                setInsuranceArray={setInsuranceCodeData}
                                propertyManagementArray={propertyManagementArray}
                                setPropertyManagementArray={setPropertyManagementArray}

                                unitArray={unitArray}
                                serviceArray={serviceArray}
                                templateArray={templateArray}
                                serviceCategoryArray={serviceCategoryArray}
                                
                                materialArray={materialArray}
                                materialCategoryArray={materialCategoryArray}
                                materialSubcategoryArray={materialSubcategoryArray}

                                offerStateArray={offerStateArray}
                                offerTypeArray={offerTypeArray}
                                partnerArray={partnerArray}

                                mimeTypeArray={mimeTypeArray}
                                materialDocumentArray={materialDocumentArray}

                                billStateArray={billStateArray}

                                systemMailArray={systemMailArray}
                            />
                        </DialogContent>
                        <DialogActions style={{
                            position: 'sticky',
                            bottom: 0,
                            background: 'white',
                            zIndex: 1000,
                            padding: 8, 
                            }}>
                            <Button sx={{float: "right"}} onClick={() => setIsOpen(false)} variant="outlined">Abbruch</Button>
                            <Button sx={{float: "right", ml: 2}} type="submit" variant="contained" disabled={isLoadgin}>Speichern</Button>
                        </DialogActions>
                    </form>
                </Dialog>

                <Typography variant="h5">
                    Projekte
                    {permissions.includes("add_project") &&  <Button sx={{float: "right"}} variant="outlined" onClick={handleNew}>Neues Projekt</Button> }
                </Typography>

                <Collapse sx={{mt: 1}} in={isLoadgin}>
                    <LinearProgress/>
                </Collapse>

                <CustomSnackbar feedback={feedback} setFeedback={setFeedback}/>

                <TextField
                    sx={{mt: 2, mb: 2}}
                    label="Suche"
                    fullWidth
                    value={searchTerm}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
                />

                <Grid container spacing={2} sx={{mt: 2}}>
                    <Grid item sm={12}>
                        <DataGrid
                            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                            rows={handleSearchTerm()}
                            columns={columns}
                            //getRowId={(row) => row.id}
                            onRowClick={(params, event, details) => {
                                if(permissions.includes("change_project")){
                                const target = event.target as HTMLElement;
                                const isDeleteIconClicked = target.closest('.MuiIconButton-root') !== null;
                                if (isDeleteIconClicked) {
                                    event.stopPropagation();
                                    openDeleteConfirmationDialog(Number(params.id));
                                } else {
                                    handleChangeId(params)
                                }
                            }}}
                            autoHeight
                        />
                    </Grid>
                </Grid>
            </>
        )
    }


}
