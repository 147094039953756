import { useMutation, useQuery } from "@tanstack/react-query";
import { IPostcode } from "../../interfaces/IPostcode";
import { customFetcher } from "../useFetch";
import { ICountry } from "../../interfaces/ICountry";
import { IState } from "../../interfaces/IState";



export const useGetPostcode = (searchString: String) => {
    const {
      data: postcodeArray,
      isLoading: isLoadingPostcode,
      refetch: refetchPostcodeArray,
    } = useQuery<IPostcode[]>({
      queryKey: ['postcode/byStateId/',searchString],
      queryFn: () => customFetcher({ endpoint: '/postcode/search/' + searchString }),
    });
  
    return { postcodeArray, isLoadingPostcode, refetchPostcodeArray};
};

export const useMutatePostcode = () => {
  const {
    mutateAsync: mutatePostcode,
    isPending: isPendingMutatePostcode,
  } = useMutation({
    mutationFn: (postcodeObject: IPostcode) =>
      customFetcher({
        endpoint: (postcodeObject.id > 0) ? `/postcode/${postcodeObject.id}` : `/postcode/`,
        method: (postcodeObject.id > 0) ? 'PUT' : 'POST',
        body: postcodeObject,
      }),
  });

  return {
    mutatePostcode,
    isPendingMutatePostcode,
  };
};


export const useGetCountry = () => {
    const {
      data: countryArray,
      isLoading: isLoadingCountry,
      refetch: refetchCountryArray,
    } = useQuery<ICountry[]>({
      queryKey: ['country/'],
      queryFn: () => customFetcher({ endpoint: '/country/' }),
    });
    return { countryArray, isLoadingCountry, refetchCountryArray};
};

export const useGetState = () => {
    const {
      data: stateArray,
      isLoading: isLoadingState,
      refetch: refetchStateArray,
    } = useQuery<IState[]>({
      queryKey: ['state/'],
      queryFn: () => customFetcher({ endpoint: '/state/' }),
    });
    return { stateArray, isLoadingState, refetchStateArray};
};