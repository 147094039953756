import { useMutation, useQuery } from "@tanstack/react-query";
import { IOfferState } from "../../interfaces/IOfferState";
import { customFetcher } from "../useFetch";


export const useGetOfferState = () => {
    const {
      data: offerStateArray,
      isLoading: isLoadingOfferState,
      refetch: refetchOfferState,
    } = useQuery<IOfferState[]>({
      queryKey: ['offer/state/'],
      queryFn: () => customFetcher({ endpoint: '/offer/state/' }),
    });
    return { offerStateArray, isLoadingOfferState, refetchOfferState};
  };