import React, { useEffect, useState } from "react";
import { ISystemMail } from "../../interfaces/ISystemMail";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { CustomCircularProgress } from "../core/CustomCircularProgress";
import { CustomEditor } from "../core/CustomEditor";


export interface IProps {
    currentObject: any;
    setCurrentObject: Function;
    systemMailArray: ISystemMail[];
    idTargetCategory: number;
    idTargetState: number;
    handleSave: () => void;
    handleAbbort: () => void;
}


export const SystemMailAutomationEditor:React.FC<IProps> = ({
    currentObject,
    setCurrentObject,
    systemMailArray,
    idTargetCategory,
    idTargetState,
    handleSave,
    handleAbbort
}) => {
    //
    const [isOpenCheckSystemMail,setIsOpenCheckSystemMail] = useState(false);
    //
    const [editSystemMail,setEditSystemMail] = useState<ISystemMail | null >(() => {
        let foundedSystemMail:ISystemMail | null = null;
        systemMailArray.filter(systemMail => systemMail.is_edit == true).map(systemMail => {
            let foundAutomation = systemMail.SystemMailAutomationArray.find( x => x.SystemMailSendCategory === idTargetCategory && (
                (idTargetCategory === 1 && idTargetState === x.send_offer_state)
                || (idTargetCategory === 2 && idTargetState === x.TaskState)
                || (idTargetCategory === 3 && idTargetState === x.ProjectState)
                || (idTargetCategory === 4 && idTargetState === x.OfferState)
                || (idTargetCategory === 6 && idTargetState === x.BillState)
            ));

            if (foundAutomation !== undefined) {
                if (foundedSystemMail !== null) { console.warn("Found multiple system mail automation!")}
            }
            foundedSystemMail = systemMail
        }) 

        if (foundedSystemMail === null) {
            handleSave();
        }
        return foundedSystemMail;
    });

    useEffect(() => {
        if (editSystemMail !== null) {
            setCurrentObject(editSystemMail)
        }
    },[editSystemMail])


    const setText = (txt: string) => {
        //console.log("INSIDE");
        //console.log(txt);
        setEditSystemMail({... editSystemMail!, body : txt});
        /*
        console.log("INSIDE");
        console.log(txt);
        setSettingObject({
            ...settingObject,
            SettingValue: txt
        })
        if(settingObject?.SettingValue !== undefined && systemMailObject !== undefined)
        {
            setSystemMailObject({... systemMailObject, Body : settingObject.SettingValue})
        }
        */
    }

    if (editSystemMail === null) {
        return(
            <>
                <Dialog open onClose={handleAbbort}>
                    <DialogContent>
                        Prüfe nach E-Mail-Vorlagen....
                        <CustomCircularProgress/>
                    </DialogContent>
                </Dialog>
            </>
        )
    }


    return(
        <>
            <Dialog maxWidth="md" fullWidth open onClose={handleAbbort}>
                <DialogContent>
                    <CustomEditor 
                        txt={editSystemMail.body} 
                        setTxt={setText}
                        dictonaryIdsArray={editSystemMail.DictonaryArray}
                        dictionaryEntryArray={[]}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleAbbort}>Abbruch</Button>
                    <Button variant="contained" onClick={handleSave}>Speichern</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}