import { useMutation, useQuery } from "@tanstack/react-query";
import { customFetcher } from "../useFetch";
import { IMaterialDocument } from "../../interfaces/IMaterialDocument";


export const useGetMaterialDocument = () => {
    const {
      data: materialDocumentArray,
      isLoading: isLoadingMaterialDocumentArray,
      refetch: refetchMaterialDocument,
    } = useQuery<IMaterialDocument[]>({
      queryKey: ['material/document/'],
      queryFn: () => customFetcher({ endpoint: '/material/document/' }),
    });
    return { materialDocumentArray, isLoadingMaterialDocumentArray, refetchMaterialDocument};
  };