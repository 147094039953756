import { useMutation, useQuery } from "@tanstack/react-query";
import { customFetcher } from "../useFetch";
import { ITaskState } from "../../interfaces/ITaskState";


export const useGetTaskState = () => {
    const {
      data: taskStateArray,
      isLoading: isLoadingTaskState,
      refetch: refetchTaskStateTask,
    } = useQuery<ITaskState[]>({
      queryKey: ['task/state/'],
      queryFn: () => customFetcher({ endpoint: '/task/state/' }),
    });
    return { taskStateArray, isLoadingTaskState, refetchTaskStateTask};
  };